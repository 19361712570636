'use client';

import { motion } from 'framer-motion';
import Link from 'next/link';
import { BsDiscord, BsTelegram } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { URLS } from '@/shared/constants';
export default function Home() {
  const [time, setTime] = useState(new Date());
  const [timeZone, setTimeZone] = useState('');
  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZoneName);
    return () => clearInterval(timerId);
  }, []);
  return <>
			<div className="m-auto flex w-full max-w-[1350px] flex-col">
				<div className="relative flex h-[75vh] flex-col items-center justify-evenly gap-8">
					<motion.div initial={{
          opacity: 0,
          y: 100
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          duration: 0.65,
          delay: 3.25,
          ease: 'easeOut'
        }} className="flex w-[90%] max-w-[95rem] flex-col items-center justify-center gap-8 px-24 py-12 text-center lg:w-full" data-sentry-element="unknown" data-sentry-source-file="page.tsx">
						<div className="flex flex-col items-center justify-center gap-4">
							<p className={'text-6xl text-primary'}>
								Object-Nimesis
							</p>
							<p className="text-3xl">More than just a company</p>
						</div>
						<p className="text-xl">
							We are in{' '}
							<span className={'text-primary'}>DEV!</span>
						</p>
						<div className="flex flex-wrap gap-6">
							<Link href={URLS.SOCIALS.DISCORD} className={'text-4xl text-primary duration-200 hover:scale-125'} data-sentry-element="Link" data-sentry-source-file="page.tsx">
								<BsDiscord data-sentry-element="BsDiscord" data-sentry-source-file="page.tsx" />
							</Link>
							<Link href={URLS.SOCIALS.TELEGRAM} className={'text-4xl text-primary duration-200 hover:scale-125'} data-sentry-element="Link" data-sentry-source-file="page.tsx">
								<BsTelegram data-sentry-element="BsTelegram" data-sentry-source-file="page.tsx" />
							</Link>
						</div>
						<div className="flex flex-col">
							<p className={'text-xl'}>
								Our time:{' '}
								<span className={'text-primary'}>
									{' '}
									{time.getHours()}:{time.getMinutes()}:
									{time.getSeconds()} {timeZone}
								</span>
							</p>
							<p className={'text-xl'}>
								Release time:{' '}
								<span className={'text-primary'}>Soon... </span>
							</p>
						</div>
					</motion.div>
				</div>
			</div>
		</>;
}